var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"main-menu"},[_c('div',{staticClass:"menu-buttons-set",attrs:{"data-cy":"navbar-element"}},[_c('span',{staticClass:"p-buttonset p-raised",staticStyle:{"display":"flex"}},[_c('router-link',{attrs:{"to":{ path: '/' }}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.$t('mainPage')),expression:"$t('mainPage')",modifiers:{"bottom":true}}],class:{
            'menu-button__active': _vm.currentRoutePath === '/',
          },attrs:{"icon":"mdi mdi-20px mdi-home","data-cy":"navbar-main-page"},on:{"click":function($event){$event.preventDefault();return _vm.menuButtonClick({ path: '/' })}}})],1),_c('div',{staticClass:"devider"}),_vm._l((_vm.menuButtons),function(button){return _c('div',{key:button.name},[(button.path)?_c('router-link',{attrs:{"to":_vm.pathGenerator(button)}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(button.label),expression:"button.label",modifiers:{"bottom":true}}],class:{
              'menu-button__active': _vm.isCurrent(button.path),
            },style:({
              display: button.scopes
                ? _vm.canI(button.scopes)
                  ? 'inline-flex'
                  : 'none'
                : 'inline-flex',
            }),attrs:{"icon":`mdi mdi-20px ${button.icon}`,"data-cy":`navbar-${button.name}`}})],1):(button.href)?_c('a',{attrs:{"href":button.href,"target":"_blank"}},[_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(button.label),expression:"button.label",modifiers:{"bottom":true}}],class:{
              'menu-button__active': _vm.isCurrent(button.path),
            },style:({
              display: button.scopes
                ? _vm.canI(button.scopes)
                  ? 'inline-flex'
                  : 'none'
                : 'inline-flex',
            }),attrs:{"icon":`mdi mdi-20px ${button.icon}`,"data-cy":`navbar-${button.name}`}})],1):_vm._e()],1)}),_c('div',{staticClass:"devider"}),_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.userFullName),expression:"userFullName",modifiers:{"bottom":true}}],attrs:{"data-cy":`navbar-logout`},on:{"click":_vm.logout}},[_c('i',{staticClass:"mdi mdi-20px mdi-logout"})])],2)]),_c('v-teleport-location',{staticClass:"p-col-3 p-mr-3",staticStyle:{"padding":"0"},attrs:{"name":"searchTeleport","tag":"div"}}),(_vm.currentMenuButton && _vm.currentMenuButton.switchers)?_c('SelectButton',{staticClass:"p-mr-2 p-raised",attrs:{"optionLabel":"label","options":_vm.currentMenuButton.switchers},scopedSlots:_vm._u([{key:"option",fn:function(slotProps){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(slotProps.option.label),expression:"slotProps.option.label",modifiers:{"bottom":true}}],staticStyle:{"height":"100%","width":"100%","position":"absolute"},attrs:{"data-cy":`navbar-${slotProps.option.name}`}}),_c('i',{class:`mdi mdi-20px ${slotProps.option.icon}`,staticStyle:{"height":"100%"}})]}}],null,false,2222018086),model:{value:(_vm.switchButton),callback:function ($$v) {_vm.switchButton=$$v},expression:"switchButton"}}):_vm._e(),_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.$t('switchTheme')),expression:"$t('switchTheme')",modifiers:{"bottom":true}}],staticClass:"p-button-blurred",attrs:{"data-cy":`navbar-switch-theme`,"icon":!_vm.isDarkTheme
        ? 'mdi mdi-20px mdi-brightness-7'
        : 'mdi mdi-20px mdi-rotate-315 mdi-moon-waning-crescent'},on:{"click":function($event){_vm.isDarkTheme = !_vm.isDarkTheme}}}),(_vm.isSubsystemsLinkVisible)?_c('SubsystemsDropdown',{attrs:{"api":_vm.api}}):_vm._e(),(_vm.isLicenseExpiring || _vm.isLicenseExpired)?_c('Message',{attrs:{"severity":_vm.warningMessagePanel.type,"closable":false}},[_vm._v(" "+_vm._s(_vm.warningMessagePanel.text)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }